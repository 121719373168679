define('loader',[
    'edap',
    'debughelpers',
    'data-enrichment/edap-data-enrichment-integration',
    'ua/edap-ua-integration',
    'hadoop/edap-hadoop-integration',
    'tagmanager/edap-tagmanager-integration',
    'demo/edap-demo-integration',
    'feature-flag/edap-feature-flag-integration',
    'init'
], function(inittedEdap, debugHelpers, dataEnrichment, ua, hadoop, tagmanager, demo, features, integrations) {
    'use strict';

    var verboseDebugName = 'logloadevents',
        loadEvents = [
            'ua.load',
            'ua.did.load',
            'tagmanager.load',
            'tagmanager.did.load',
            'features.load',
            'features.did.load'
        ],
        i;

    // If we are not in debug mode then we will need to add the load/did.load events to the ignore console events
    if (!debugHelpers.isInDebugMode(verboseDebugName)) {
        for (i = 0; i < loadEvents.length; i++) {
            inittedEdap['public'].addIgnoredConsoleEvent(loadEvents[i]);
        }
    }

    // this one should always be first to ensure it gets to augment the data object before any other integration consumes it
    dataEnrichment.init();

    if (!inittedEdap.globalOptions.skipGA) {
        ua.init();
    }
    hadoop.init();
    tagmanager.init();
    demo.init();
    features.init();

    // this one should always be last to make sure all other integrations have registered their callbacks
    integrations.init();

    return inittedEdap;
});

